<template style="height=100%">
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  // import {mapActions} from "vuex"
  export default {
    name: "app",
    data() {
      return {}
    },
    mounted() {
      this.init()
      // this.getPlatformOption(this)
      // this.getDict(this)
    },
    methods: {
      // ...mapActions(["getPlatformOption", "getDict"]),
      init() {
        // console.log(this.$cookies.get("user_token"))
      }
    }
  }
</script>
<style lang="scss" scoped src="./assets/styles/home.scss"></style>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
    /* text-align: center; */
    color: #2c3e50;
    height: 100%;
  }
</style>
