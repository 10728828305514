import Vue from "vue"
import Vuex from "vuex"
import CreatePersistedstate from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    CreatePersistedstate({
      storage: window.sessionStorage
    })
  ],
  state: {
    // platformOption: {}, //平台数据
    // opcoNameOption: [] //子公司
  },
  getters: {
    // permissions: (state) => {
    //   return state.Authority.AUTHORITY.permissions
    // }
  },
  mutations: {},
  actions: {
    // async getPlatformOption(Store, that) {
    //   let res = await that.$http.get("/apis/getPlatform")
    //   if (res.code !== 200) return
    //   Store.state.platformOption = res.data
    // },
    // async getDict(Store, that) {
    //   let res = await that.$http.get("/apis/getDict")
    //   if (res.code !== 200) return
    //   Store.state.opcoNameOption = JSON.parse(res.data.find((item) => item.name === "opcoName").value.replace(/“/g, '"'))
    // }
  },
  modules: {}
})
